<template>
  <v-card class="mx-auto mt-5 mb-5" elevation="4" max-width="600">
    <v-card-title class="justify-center">
      AnnuityCheck Registration
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="firstName"
              :rules="req"
              label="First name"
              dense
              :loading="loading"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              v-model="lastName"
              :rules="req"
              label="Last name"
              dense
              :loading="loading"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="email"
              label="E-mail"
              :rules="req"
              dense
              :loading="loading"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field-simplemask
              label="Phone Number"
              v-model="phoneNumber"
              v-bind:options="{
                inputMask: '(###) ### - ####',
                outputMask: '##########',
                empty: null,
                applyAfter: false,
                alphanumeric: false,
                lowerCase: false,
              }"
              v-bind:properties="{
                prefix: '',
                suffix: '',
                readonly: false,
                disabled: false,
                outlined: false,
                clearable: false,
                dense: true,
                placeholder: '',
                required: '',
                rules: [
                  (v) => !!v || 'Phone number is required',
                  (v) =>
                    (v && v.length == 16) ||
                    'Phone number should be 10 digits long',
                ],
              }"
              v-bind:focus="focus"
              v-on:focus="focus = false"
            ></v-text-field-simplemask>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="address"
              dense
              :loading="loading"
              label="Address Line"
              autocomplete="shipping street-address"
              :rules="req"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" md="4">
            <v-text-field
              v-model="city"
              dense
              :loading="loading"
              label="City"
              :rules="req"
              autocomplete="shipping locality"
            ></v-text-field>
          </v-col>
          <v-col cols="6" md="4">
            <v-select
              dense
              :loading="loading"
              v-model="state"
              :items="all_states"
              :menu-props="{ maxHeight: '400' }"
              label="State"
              :rules="[(v) => !!v || 'State is required']"
              item-value="abbreviation"
              item-text="name"
              autocomplete="shipping region"
            ></v-select>
          </v-col>
          <v-col cols="6" md="4">
            <v-text-field
              dense
              :loading="loading"
              v-model="zip"
              counter="5"
              label="ZIP / Postal Code"
              :rules="req"
              type="number"
              autocomplete="shipping postal-code"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              dense
              :loading="loading"
              v-model="currentAffiliations"
              label="List your current IMOs and BD affiliations; seperate each by a comma"
              :rules="req"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              v-model="annuityPremium"
              :items="annuityPremiumItems"
              dense
              :loading="loading"
              label="Annuity Premium Written: Last 12 months"
              :rules="req"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="password"
              label="Create Password"
              type="password"
              :rules="req"
              dense
              :loading="loading"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="password_conf"
              label="Confirm Password"
              type="password"
              :rules="req"
              dense
              :loading="loading"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <div>
        <router-link to="/login">Log into existing account</router-link>
      </div>
      <v-spacer />
      <v-btn color="primary" @click="submit" :loading="loading">
        Register
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<style scoped>
.v-card__title {
  text-align: center;
  width: 100%;
}
</style>

<script>
import hasuraService from "@/services/hasura.service.js";

export default {
  name: "SignUp",
  mixins: [hasuraService],
  methods: {
    submit: function () {
      if (this.$refs.form.validate()) {
        if (this.password != this.password_conf) {
          this.$store.state.commit(
            "set_snackbar",
            "Your passwords do not match"
          );
          return;
        }
        this.loading = true;
        this.registerUser(
          this.email,
          this.firstName,
          this.lastName,
          this.password,
          this.phoneNumber,
          this.annuityPremium,
          this.currentAffiliations,
          this.address,
          this.city,
          this.state,
          this.zip
        ).then((r) => {
          this.loading = false;
          let agent_name = `${this.firstName} ${this.lastName}`;
          if (r.ok) {
            this.sendTemplateEmail(this.email, agent_name, "new-sign-up", {
              name: agent_name,
            });
            let adminHtml = `
              Marketing: Please follow up with the newly registered agent
              below for confirmation and additional licensing/marketing
              support opportunities. <span style='color: red'>This agent
              was not in our system prior to registering for AnnuityCheck
              access.</span><br>
              <br>
              New Agent Registered:<br>
              ${agent_name}<br>
              <br>
              Address: ${this.address}, ${this.city}, ${this.state}, ${this.zip}<br>
              Phone: ${this.phoneNumber}<br>
              Email: ${this.email}<br>
              Current IMOs: ${this.currentAffiliations}<br>
              Annuity Premium: ${this.annuityPremium}<br>
              `;
            // 3. Send Email to steve/kevin
            let admins = [
              { address: "steve@annuitycheck.com", name: "Steve Hutchinson" },
              { address: "kevin@ecamarketing.com", name: "Kevin" },
              { address: "greyson314@gmail.com", name: "Grey" },
              { address: "matt@annuitycheck.com", name: "Matt Harris" },
            ];
            for (let admin of admins) {
              this.sendEmail(
                admin.address,
                admin.name,
                "MI6, do you copy? The raven has taken flight. A NEW agent has registered for access to AnnuityCheck.",
                adminHtml
              );
            }
            this.$store.commit(
              "set_snackbar",
              "Please Login to confirm your account"
            );
            this.$router.push("/login");
          } else {
            this.$store.commit(
              "set_snackbar",
              "Error!  Failed to create account: " + r.reason
            );
          }
        });
      }
    },
  },
  data: () => {
    return {
      req: [(v) => !!v || "Field is required"],
      loading: false,
      firstName: "",
      lastName: "",
      phoneNumber: "",
      address: "",
      city: "",
      zip: "",
      state: "",
      country: "",
      currentAffiliations: "",
      email: "",
      password: "",
      password_conf: "",
      valid: true,
      annuityPremium: "",
      annuityPremiumItems: [
        "Less than $5,000,000 Premium",
        "Between $5,000,000 and $10,000,000 Premium",
        "Over $10,000,000 Premium",
      ],
    };
  },
};
</script>
